<template>
	<div>
		<van-nav-bar title="订阅购买" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
		<van-row type="flex" justify="center">
			<van-col span="24">
				<van-radio-group v-model="radio">
					<van-cell-group>
						<van-cell clickable>
							<van-card num="999" price="9.90" desc="" title="月付" thumb="https://img.yzcdn.cn/vant/ipad.jpeg" @click="cardClick1()"/>
							<template #right-icon>
								<van-radio name="1" />
							</template>
						</van-cell>
						<van-cell clickable>
							<van-card num="0" price="30.00" desc="以售完" title="季付" thumb="https://img.yzcdn.cn/vant/ipad.jpeg" @click="cardClick2()"/>
							<template #right-icon>
								<van-radio name="2" disabled/>
							</template>
						</van-cell>
						<van-cell clickable>
							<van-card num="999" price="99.00" desc="内含年费专线" title="年付" thumb="https://img.yzcdn.cn/vant/ipad.jpeg" @click="cardClick3()"/>
							<template #right-icon>
								<van-radio name="3" />
							</template>
						</van-cell>
					</van-cell-group>
				</van-radio-group>
			</van-col>
		</van-row>
		<van-row>
			<van-col span="10" offset="14">
				<van-button type="danger" size="normal" @click="paySub" icon="cart-o">购买</van-button>
			</van-col>
		</van-row>
	</div>
</template>
<van-icon name="" color="#1989fa" />
<style>
	.custom-title {
		margin-right: 4px;
		vertical-align: middle;
	}

	.search-icon {
		font-size: 16px;
		line-height: inherit;
	}
</style>
<script>
	export default {
		data() {
			return {
				radio: '1',
			};
		},
		methods: {
			onClickLeft() {
				window.history.go(-1)
			},
			onClickRight() {
			},
			paySub(){
				window.location.href = '/pay?type='+this.radio; 
				console.log(this.radio);
			},
			cardClick1() {
				this.radio = '1';
			},
			cardClick2() {
				this.$toast('以售完');
			},
			cardClick3() {
				this.radio = '3';
			},
		}
	};
</script>
